const people = [
    {
      name: 'DEWEN MOOTOOSAMY',
      role: 'Founder | CEO',
      denomination: 'FCCA',
      desc: 
      'Worked at Kemp Chatteris and Coopers & Lybrand. Involved in consultancy assignments in Rwanda, Tanzania and Burundi as a Chartered Certified Accountant and a registered Insolvency practitioner. Has more than 2 decade of private practice experience and has a wide understanding of businesses and has worked across a variety of sectors including banks and insurance companies. He was a partner of a firm in Rwanda, GN Partners and has also been a member of the Audit Panel of the Financial Reporting Council.',
      imageUrl:
        'https://st4.depositphotos.com/9998432/22597/v/450/depositphotos_225976914-stock-illustration-person-gray-photo-placeholder-man.jpg',
    },

    {
        name: 'NAMBEN MOOTOOSAMY',
        role: 'SENIOR ACCOUNTANT',
        denomination: 'ACCA | BSc (Hons) Financial Economics',
        desc: 'Graduate from the University of Leicester, United Kingdom and a qualified ACCA Affiliate. He has had his training in the firm before 2011 and has been in full time employment since 2012. He has been involved in the Tax Department, Auditing section, Project Appraisal exercises and Advisory.',
        imageUrl:
          'https://media.licdn.com/dms/image/C4E03AQEeuGEHT1HpwQ/profile-displayphoto-shrink_400_400/0/1516951609265?e=1691625600&v=beta&t=R2HEQO4sj4ufi1av32TUDChxWB1kFl79pEsritVBbcs',
      },

      {
        name: 'DIDIER HWL',
        role: 'SENIOR ACCOUNTANT',
        denomination: 'FCCA',
        desc: 'Fellow member of the Association of Chartered Certified Accountants. With over 15 years of experience in the accounting, auditing and taxation world.',
        imageUrl:
          'https://media.licdn.com/dms/image/C4E03AQG9n_tw1XpdrQ/profile-displayphoto-shrink_400_400/0/1517589894281?e=1691625600&v=beta&t=Or3W4etsBnh8XzconjCDX59hKD_jybdieUQ_dBtfFME',
      },
    // More people...
  ]
  
  export default function staff() {
    return (
      <div id="team" className="bg-white py-24 sm:py-32">
        <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
          <div className="max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">MEET THE TEAM</h2>
          </div>
          <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
            {people.map((person) => (
              <li key={person.name}>
                <div class="py-8 px-8 max-w-sm mx-auto bg-white rounded-xl shadow-lg space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                  <img className="h-16 w-16 rounded-full" src={person.imageUrl} alt="" />
                  <div>
                    <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                    <h4 className="text-sm font-semibold leading-6 text-indigo-600">{person.role}</h4>
                    <h5 className="text-sm font-semibold leading-6 text-indigo-600">{person.denomination}</h5>
                    <h6 className="mt-6 text-lg leading-8 text-gray-600">{person.desc}</h6>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
  