export default function Navibar() {
    return (
      <div id="home">
        <nav class="fixed top-0 w-full bg-neutral-100 bg-opacity-100 py-3 shadow-xl">
          <div class="container mx-auto text-center text-white font-bold">
            {[
              ['HOME', '/#home'],
              ['ABOUT US', '/#about'],
              ['SERVICES', '/#service'],
              ['TEAM', '/#team'],
              ['CONTACT', '/#contact'],
            ].map(([title, url]) => (
              <a href={url} className="rounded-lg px-2 py-3 text-slate-900 font-medium hover:bg-gray-300 hover:text-slate-1000 font-bold">{title}</a>
            ))}
        </div>
      </nav>
      </div>
    )
  };
  