const portfolio = [
    {
      name: 'BUSINESS RESTRUCTURING',
      desc: 
      'A business restructuring exercise will help management to reorganize its structure and operations to be more efficient and cost effective.',
      imageUrl:
        'https://images.squarespace-cdn.com/content/55d1e076e4b0be96a30dc726/1457094240027-KOPT5WF94K91FVT3M0RF/computerworld_consultancy_icon?content-type=image%2Fpng',
    },

    {
      name: 'TAX & CONSULTANCY',
      desc: 
      'The tax world is full of complex wordings and heavy penalties for non-compliance. We provide tax compliance and assists in tax review by the local authority. Our client worry about their business, we take care of the tax works.',
      imageUrl:
        'https://png.pngtree.com/png-vector/20190405/ourmid/pngtree-tax-file-document-icon-png-image_913552.jpg',
    },

    {
      name: 'DEBT RECOVERY',
      desc: 
      'A bad debt recovery is a business debt from loans, credit lines and accounts receivables. We always advise our client on the importance of being prudence with debt management.',
      imageUrl:
        'https://www.clipartmax.com/png/middle/56-561342_syrian-lebanese-commercial-bank-debt-collection-icon.png',
    },

    {
      name: 'AUDIT & ASSURANCE',
      desc: 
      'Audit is much more than a compliance exercise and we devote ourselves to provide an independent professional service to our clients to enable decision makers to make more informed and better decisions.',
      imageUrl:
        'https://cdn-icons-png.flaticon.com/256/4308/4308094.png',
    },

    {
      name: 'BUSINESS PLAN',
      desc: 
      'At any point in the lifetime of an organization, management will seek to financially assess their long-term strategic plan to recognize whether their business goals are still attainable and their current plan is in line in achieving these goals.',
      imageUrl:
        'https://cdn.icon-icons.com/icons2/1859/PNG/512/clipboardplan_117967.png',
    },

    {
      name: 'WINDING UP OF COMPANIES',
      desc: 
      'Some businesses have a lifetime and require being wind up after the business objectives have been achieved. On the other hand, winding up procedures may also be initiated through creditors.',
      imageUrl:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyFNNhMnTlciHeWdIvMM7HjF9_ddKhXEzf-g&usqp=CAU',
    },

    {
      name: 'ACCOUNTING',
      desc: 
      'The system of recording, keeping and interpreting adequate records is the foundations to keep a healthy business and to be compliant with regulations and laws of Mauritius.',
      imageUrl:
        'https://cdn.iconscout.com/icon/premium/png-256-thumb/accounting-book-3471725-2905019.png?f=webp',
    },

    {
      name: 'RAISING OF CAPITAL',
      desc: 
      'We understand how difficulty it can be in present economic conditions to gain access to equity and debt financing, we assist our client in choosing what is best to grow their business.',
      imageUrl:
        'https://globalcfoservice.com/img/info-4.png',
    },

    {
      name: 'SECRETARIAL WORKS',
      desc: 
      'Compliance with laws and regulations requires organizations to keep proper records of the administrative and secretarial aspect of the organization.',
      imageUrl:
        'https://cdn-icons-png.flaticon.com/256/921/921385.png',
    },

    {
      name: 'PROJECT APPRAISALS',
      desc: 
      'As a businessman, we understand that the new business ideas pop up from time to time. We can assist by providing an assessment of a project’s viability and a structured way to approach a new business venture.    ',
      imageUrl:
        'https://cdn-icons-png.flaticon.com/256/3976/3976562.png',
    },

    {
      name: 'PAYROLL',
      desc: 
      'Organization often seek to outsource their payroll department and we ensure that payroll are computed as per the law and that the staff are rewarded in due time. Payroll packages are tailor-made for each client to ensure their requirements are met.',
      imageUrl:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSR4b3_sj-5liPC2Uj6cI54f_o8efJg9sRD_jRJrgJr08tJyNvpa6YhznRDAh6PavoVowE&usqp=CAU',
    },

    // More people...
  ]
  
  export default function porfolio_services() {
    return (
      <div id="service" className="bg-gray py-24 sm:py-32">
        <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
          <div className="max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">OUR ARRAY OF SERVICES</h2>
          </div>
          <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
            {portfolio.map((portfo) => (
              <li key={portfo.name}>
                <div class="py-8 px-8 max-w-sm mx-auto bg-white rounded-xl shadow-lg space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                  <img className="h-16 w-16 rounded-full" src={portfo.imageUrl} alt="" />
                  <div>
                    <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{portfo.name}</h3>
                    <h6 className="mt-6 text-lg leading-8 text-gray-600">{portfo.desc}</h6>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
  