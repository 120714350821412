export default function Footer() {
    return (
        <div id="footer" className="bg-white py-5 sm:py-5">
            <footer>
                <div id="footer-section" class="text-center">
                    <div class="container">
                    <div class="row">
                        <div class="col-sm-8 col-sm-offset-2">
                            <p class="copyright"> 
                                &copy; Created and Maintained By<br/>
                                <a href="https://www.linkedin.com/in/kovcoo/" target="_blank">
                                    Kevissen
                                </a>
                            </p>
                        </div>
                    </div>
                    </div>
                </div>
            </footer>
        </div>
    )
};
