const abt = [
    {
      name: 'A leading firm of Chartered Accountants, Tax Consultants, Business advisors and Auditors.',
      desc: 
      'With a portfolio ranging from financial and business services to corporates entities and individuals in Mauritius as well as Rodrigues, supporting them to achieve growth and success. We are competitive, efficient and human-centered in our approach.',
      
    },

    {
      name: 'Business with a personal touch',
      desc: 
      'DGM Consultants is a trusted and reputable financial services provider specializing in comprehensive accounting and advisory solutions. With a team of highly skilled professionals and a commitment to excellence, we offer a wide range of services tailored to meet the unique needs of your businesses.',
  
    },


    // More people...
  ]
  
  export default function aboutus() {
    return (
      <div id="about" className="bg-white py-24 sm:py-32">
        <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
          <div className="max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">ABOUT US</h2>
          </div>
          <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
            {abt.map((portfo) => (
              <li key={portfo.name}>
                <div class="py-8 px-8 max-w-sm mx-auto bg-white rounded-xl shadow-lg space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                  
                  <div>
                    <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{portfo.name}</h3>
                    <h6 className="mt-6 text-lg leading-8 text-gray-600">{portfo.desc}</h6>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }