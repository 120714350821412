export default function ContactUs() {
    return (
        <div id="contact" className="bg-gray py-24 sm:py-32">
        <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
          <div className="max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">GET IN TOUCH</h2>
          </div>
          <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
              <li>
                <div class="py-8 px-8 max-w-sm mx-auto bg-white rounded-xl shadow-lg space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                  <img className="h-16 w-16 rounded-full" src="https://png.pngitem.com/pimgs/s/20-202361_phone-png-blue-phone-flat-icon-png-transparent.png" alt="" />
                  <div>
                    <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">Contact Details</h3>
                    <div class="ml-6 grow">
                        <h4 className="text-sm font-semibold leading-6 text-indigo-600">Address</h4>
                            <p><a href="https://goo.gl/maps/s5eTFM8UXwPC3u4j9">
                                Suite 703, Level 7, Richard House, Remy Ollier Street, Port Louis, Mauritius</a></p>
                        <h4 className="text-sm font-semibold leading-6 text-indigo-600">Phone</h4>
                            <p>
                                <a href="tel:+2302178926"><i class="pe-7s-phone">(+230)217-8926</i></a></p>
                        <h4 className="text-sm font-semibold leading-6 text-indigo-600">Mobile</h4>
                            <p >
                                <a href="tel:+23052548614"><i class="pe-7s-phone">(+230)5254-8614</i></a></p>
                        <h4 className="text-sm font-semibold leading-6 text-indigo-600">Email</h4>
                            <p>
                            <a href="dgm.cons.mu@inet.mu" type="email">DGM.CONS@INTNET.MU</a></p>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div class="md:mb-12 lg:mb-0">
                    <div
                        class="relative h-[700px] rounded-lg shadow-lg dark:shadow-black/20">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3745.346554580014!2d57.50427001491829!3d-20.161291186474596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x217c51a632544111%3A0x1963d7b4fc32faa6!2sDGM%20Consultants%20LTD!5e0!3m2!1sen!2smu!4v1686140494996!5m2!1sen!2smu"
                            class="absolute left-0 top-0 h-full w-full rounded-lg"
                            frameborder="1"></iframe>
                        </div>
                    </div>
              </li>
          </ul>

          
        </div>
      </div>
    )
};
