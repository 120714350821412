import React, { useState, useEffect } from 'react';

const HeroSection = () => {
  const texts = ["Chartered Certified Accountants", "Focus on your business...", "We do the financials..."];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section class="mb-50 overflow-hidden">
        <div className="hero-section">
        </div>
        <div class="relative overflow-hidden bg-cover bg-no-repeat bg-[50%] h-[750px] bg-[url('./images/landing.jpg')]">
            
            <div class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed bg-[hsla(0,0%,0%,0.75)]">
                <div class="flex h-full items-center justify-center">
                <div class="px-6 text-center text-white md:px-12">
                    <h1 class="mt-6 mb-16 text-5xl font-bold tracking-tight md:text-6xl xl:text-7xl" >DGM Consultants</h1>
                    <h2 class="mt-6 mb-16 text-5xl font-bold tracking-tight md:text-6xl xl:text-7xl" >{texts[currentTextIndex]}</h2>
                </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default HeroSection;
  